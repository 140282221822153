/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-28T10:16:42
 */
import type {
  BodyLoginLoginAccessToken,
  BodyLoginResetPassword,
  Msg,
  Token,
  User,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * OAuth2 compatible token login, get an access token for future requests
 * @summary Login Access Token
 */
export const loginLoginAccessToken = (
  bodyLoginLoginAccessToken: BodyLoginLoginAccessToken,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formUrlEncoded = new URLSearchParams();
  if (bodyLoginLoginAccessToken.grant_type !== undefined) {
    formUrlEncoded.append("grant_type", bodyLoginLoginAccessToken.grant_type);
  }
  formUrlEncoded.append("username", bodyLoginLoginAccessToken.username);
  formUrlEncoded.append("password", bodyLoginLoginAccessToken.password);
  if (bodyLoginLoginAccessToken.scope !== undefined) {
    formUrlEncoded.append("scope", bodyLoginLoginAccessToken.scope);
  }
  if (bodyLoginLoginAccessToken.client_id !== undefined) {
    formUrlEncoded.append("client_id", bodyLoginLoginAccessToken.client_id);
  }
  if (bodyLoginLoginAccessToken.client_secret !== undefined) {
    formUrlEncoded.append("client_secret", bodyLoginLoginAccessToken.client_secret);
  }

  return apiAxiosInstance<Token>(
    {
      url: `/api/v1/login/access-token`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options,
  );
};
/**
 * Test access token
 * @summary Test Token
 */
export const loginTestToken = (options?: SecondParameter<typeof apiAxiosInstance>) => {
  return apiAxiosInstance<User>(
    { url: `/api/v1/login/test-token`, method: "POST" },
    options,
  );
};
/**
 * Password Recovery
 * @summary Recover Password
 */
export const loginRecoverPassword = (
  email: string,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Msg>(
    { url: `/api/v1/password-recovery/${email}`, method: "POST" },
    options,
  );
};
/**
 * Reset password
 * @summary Reset Password
 */
export const loginResetPassword = (
  bodyLoginResetPassword: BodyLoginResetPassword,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Msg>(
    {
      url: `/api/v1/reset-password/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: bodyLoginResetPassword,
    },
    options,
  );
};
export type LoginLoginAccessTokenResult = NonNullable<
  Awaited<ReturnType<typeof loginLoginAccessToken>>
>;
export type LoginTestTokenResult = NonNullable<
  Awaited<ReturnType<typeof loginTestToken>>
>;
export type LoginRecoverPasswordResult = NonNullable<
  Awaited<ReturnType<typeof loginRecoverPassword>>
>;
export type LoginResetPasswordResult = NonNullable<
  Awaited<ReturnType<typeof loginResetPassword>>
>;
