/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-28T10:16:42
 */
import type {
  Tag,
  TagCreate,
  TagUpdate,
  TagsReadAllTagsParams,
  TagsReadTagsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve tags.
 * @summary Read Tags
 */
export const tagsReadTags = (
  params?: TagsReadTagsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag[]>(
    { url: `/api/v1/tags/`, method: "GET", params },
    options,
  );
};
/**
 * Create new tag.
 * @summary Create Tag
 */
export const tagsCreateTag = (
  tagCreate: TagCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag>(
    {
      url: `/api/v1/tags/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: tagCreate,
    },
    options,
  );
};
/**
 * Get tag by ID.
 * @summary Read Tag
 */
export const tagsReadTag = (
  tagId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag>(
    { url: `/api/v1/tags/${tagId}`, method: "GET" },
    options,
  );
};
/**
 * Update a tag.
 * @summary Update Tag
 */
export const tagsUpdateTag = (
  tagId: number,
  tagUpdate: TagUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag>(
    {
      url: `/api/v1/tags/${tagId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: tagUpdate,
    },
    options,
  );
};
/**
 * Delete a tag.
 * @summary Delete Tag
 */
export const tagsDeleteTag = (
  tagId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag>(
    { url: `/api/v1/tags/${tagId}`, method: "DELETE" },
    options,
  );
};
/**
 * Retrieve all tags (admin function only).
 * @summary Read All Tags
 */
export const tagsReadAllTags = (
  params?: TagsReadAllTagsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Tag[]>(
    { url: `/api/v1/tags/all`, method: "GET", params },
    options,
  );
};
export type TagsReadTagsResult = NonNullable<Awaited<ReturnType<typeof tagsReadTags>>>;
export type TagsCreateTagResult = NonNullable<
  Awaited<ReturnType<typeof tagsCreateTag>>
>;
export type TagsReadTagResult = NonNullable<Awaited<ReturnType<typeof tagsReadTag>>>;
export type TagsUpdateTagResult = NonNullable<
  Awaited<ReturnType<typeof tagsUpdateTag>>
>;
export type TagsDeleteTagResult = NonNullable<
  Awaited<ReturnType<typeof tagsDeleteTag>>
>;
export type TagsReadAllTagsResult = NonNullable<
  Awaited<ReturnType<typeof tagsReadAllTags>>
>;
