/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-28T10:16:42
 */
import type {
  AnalysePrintJobResponse,
  BodyPrintJobsCreatePrintJobGcodeRemote,
  BodyPrintJobsCreatePrintJobWithPrinter,
  BodyPrintJobsCreatePrintJobWithPrinterChunked,
  BodyPrintJobsCreatePrintJobWithPrinterPrint,
  BodyPrintJobsCreatePrintJobWithPrinterPrintFromGcodeUrl,
  BodyPrintJobsCreatePrintJobZip,
  BodyPrintJobsCreatePrintJobZipRemote,
  BodyPrintJobsFinishPrintJobWithPrinter,
  BodyPrintJobsUploadFirstLayerCsv,
  BodyPrintJobsUploadGcodeFromBrowser,
  BodyPrintJobsUploadGcodeFromFile,
  BodyPrintJobsUploadGcodeFromUrl,
  BodyPrintJobsUploadGcodeToPrinter,
  CaxtonHeatmapResponse,
  CreatePrintJobZipResponse,
  PrintJob,
  PrintJobCreate,
  PrintJobCsv,
  PrintJobDownload,
  PrintJobGcode,
  PrintJobResponse,
  PrintJobUpdate,
  PrintJobsCreateCaxtonHeatmapParams,
  PrintJobsDeleteAllPrintJobsParams,
  PrintJobsReadPrintJobsPaginatedParams,
  PrintJobsReadPrintJobsParams,
  PrintJobsStatusResponse,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve print_jobs.
 * @summary Read Print Jobs Paginated
 */
export const printJobsReadPrintJobsPaginated = (
  params?: PrintJobsReadPrintJobsPaginatedParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob[]>(
    { url: `/api/v1/print-jobs/`, method: "GET", params },
    options,
  );
};
/**
 * Create new print_job.
 * @summary Create Print Job
 */
export const printJobsCreatePrintJob = (
  printJobCreate: PrintJobCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printJobCreate,
    },
    options,
  );
};
/**
 * Retrieve print_jobs.
 * @summary Read Print Jobs
 */
export const printJobsReadPrintJobs = (
  params?: PrintJobsReadPrintJobsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobResponse[]>(
    { url: `/api/v1/print-jobs/all`, method: "GET", params },
    options,
  );
};
/**
 * Delete a print_job.
 * @summary Delete All Print Jobs
 */
export const printJobsDeleteAllPrintJobs = (
  params?: PrintJobsDeleteAllPrintJobsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob>(
    { url: `/api/v1/print-jobs/all`, method: "DELETE", params },
    options,
  );
};
/**
 * Get print_job by ID.
 * @summary Read Print Job By Id
 */
export const printJobsReadPrintJobById = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobResponse>(
    { url: `/api/v1/print-jobs/${id}/admin/`, method: "GET" },
    options,
  );
};
/**
 * Count print_jobs.
 * @summary Count Print Jobs
 */
export const printJobsCountPrintJobs = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/print-jobs/count`, method: "GET" },
    options,
  );
};
/**
 * Returns total success rate for all print jobs for a user.
 * @summary Get Success Rate
 */
export const printJobsGetSuccessRate = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/print-jobs/success-rate`, method: "GET" },
    options,
  );
};
/**
 * Create new print_job.
 * @summary Create Print Job Zip
 */
export const printJobsCreatePrintJobZip = (
  bodyPrintJobsCreatePrintJobZip: BodyPrintJobsCreatePrintJobZip,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("zip_file", bodyPrintJobsCreatePrintJobZip.zip_file);

  return apiAxiosInstance<CreatePrintJobZipResponse>(
    {
      url: `/api/v1/print-jobs/zip`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Create new print_job.
 * @summary Create Print Job Zip Remote
 */
export const printJobsCreatePrintJobZipRemote = (
  bodyPrintJobsCreatePrintJobZipRemote: BodyPrintJobsCreatePrintJobZipRemote,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("zip_file", bodyPrintJobsCreatePrintJobZipRemote.zip_file);

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/zip`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Create new print_job from just a gcode_file
 * @summary Create Print Job Gcode Remote
 */
export const printJobsCreatePrintJobGcodeRemote = (
  bodyPrintJobsCreatePrintJobGcodeRemote: BodyPrintJobsCreatePrintJobGcodeRemote,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("gcode_file", bodyPrintJobsCreatePrintJobGcodeRemote.gcode_file);

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/gcode`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Create new print_job.
 * @summary Create Print Job With Printer Chunked
 */
export const printJobsCreatePrintJobWithPrinterChunked = (
  bodyPrintJobsCreatePrintJobWithPrinterChunked: BodyPrintJobsCreatePrintJobWithPrinterChunked,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("gcode_obj", bodyPrintJobsCreatePrintJobWithPrinterChunked.gcode_obj);
  formData.append("data", bodyPrintJobsCreatePrintJobWithPrinterChunked.data);
  formData.append(
    "chunk_info",
    bodyPrintJobsCreatePrintJobWithPrinterChunked.chunk_info,
  );

  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/remote/start-job/chunked`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Create new print_job.
 * @summary Create Print Job With Printer
 */
export const printJobsCreatePrintJobWithPrinter = (
  bodyPrintJobsCreatePrintJobWithPrinter: BodyPrintJobsCreatePrintJobWithPrinter,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("gcode_obj", bodyPrintJobsCreatePrintJobWithPrinter.gcode_obj);
  formData.append("data", bodyPrintJobsCreatePrintJobWithPrinter.data);

  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/remote/start-job`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Send command print to printer and create new print_job.
 * @summary Create Print Job With Printer Print
 */
export const printJobsCreatePrintJobWithPrinterPrint = (
  bodyPrintJobsCreatePrintJobWithPrinterPrint: BodyPrintJobsCreatePrintJobWithPrinterPrint,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formUrlEncoded = new URLSearchParams();
  formUrlEncoded.append("data", bodyPrintJobsCreatePrintJobWithPrinterPrint.data);

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/start-job/filename`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options,
  );
};
/**
 * Upload gcode file from binary data
 * @summary Upload Gcode From File
 */
export const printJobsUploadGcodeFromFile = (
  bodyPrintJobsUploadGcodeFromFile: BodyPrintJobsUploadGcodeFromFile,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("gcode_file", bodyPrintJobsUploadGcodeFromFile.gcode_file);

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/gcode/uploadfile`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Upload gcode file from binary data
 * @summary Upload Gcode From Browser
 */
export const printJobsUploadGcodeFromBrowser = (
  bodyPrintJobsUploadGcodeFromBrowser: BodyPrintJobsUploadGcodeFromBrowser,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("gcode_file", bodyPrintJobsUploadGcodeFromBrowser.gcode_file);
  formData.append("folder_path", bodyPrintJobsUploadGcodeFromBrowser.folder_path);
  formData.append(
    "printer_id",
    bodyPrintJobsUploadGcodeFromBrowser.printer_id.toString(),
  );

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/client/gcode/uploadfile`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Upload gcode file from binary data
 * @summary Upload Gcode To Printer
 */
export const printJobsUploadGcodeToPrinter = (
  bodyPrintJobsUploadGcodeToPrinter: BodyPrintJobsUploadGcodeToPrinter,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formUrlEncoded = new URLSearchParams();
  formUrlEncoded.append("bucket_file", bodyPrintJobsUploadGcodeToPrinter.bucket_file);

  return apiAxiosInstance<unknown>(
    {
      url: `/api/v1/print-jobs/printer/gcode/uploadfile`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options,
  );
};
/**
 * Upload gcode file from url
 * @summary Upload Gcode From Url
 */
export const printJobsUploadGcodeFromUrl = (
  bodyPrintJobsUploadGcodeFromUrl: BodyPrintJobsUploadGcodeFromUrl,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formUrlEncoded = new URLSearchParams();
  formUrlEncoded.append("data", bodyPrintJobsUploadGcodeFromUrl.data);

  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/remote/gcode/upload`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options,
  );
};
/**
 * Upload gcode file from url and send command print to printer and create new print_job.
 * @summary Create Print Job With Printer Print From Gcode Url
 */
export const printJobsCreatePrintJobWithPrinterPrintFromGcodeUrl = (
  bodyPrintJobsCreatePrintJobWithPrinterPrintFromGcodeUrl: BodyPrintJobsCreatePrintJobWithPrinterPrintFromGcodeUrl,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formUrlEncoded = new URLSearchParams();
  formUrlEncoded.append(
    "data",
    bodyPrintJobsCreatePrintJobWithPrinterPrintFromGcodeUrl.data,
  );

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/start-job/upload`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options,
  );
};
/**
 * Cancel current print_job.

TODO: Make a cancellation event here - DD
 * @summary Cancel Print Job
 */
export const printJobsCancelPrintJob = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobsStatusResponse>(
    { url: `/api/v1/print-jobs/remote/cancel-job`, method: "GET" },
    options,
  );
};
/**
 * Finish existing print_job.
 * @summary Finish Print Job With Printer
 */
export const printJobsFinishPrintJobWithPrinter = (
  bodyPrintJobsFinishPrintJobWithPrinter: BodyPrintJobsFinishPrintJobWithPrinter,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("csv_obj", bodyPrintJobsFinishPrintJobWithPrinter.csv_obj);
  formData.append("data", bodyPrintJobsFinishPrintJobWithPrinter.data);

  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/remote/end-job`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Upload first layer csv file to print job
 * @summary Upload First Layer Csv
 */
export const printJobsUploadFirstLayerCsv = (
  bodyPrintJobsUploadFirstLayerCsv: BodyPrintJobsUploadFirstLayerCsv,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("csv_obj", bodyPrintJobsUploadFirstLayerCsv.csv_obj);
  formData.append("data", bodyPrintJobsUploadFirstLayerCsv.data);

  return apiAxiosInstance<PrintJobsStatusResponse>(
    {
      url: `/api/v1/print-jobs/remote/first-layer-upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Download print job
 * @summary Download Print Job
 */
export const printJobsDownloadPrintJob = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobDownload>(
    { url: `/api/v1/print-jobs/remote/download/print_job/${id}`, method: "GET" },
    options,
  );
};
/**
 * Post print_job by ID to analyse all the images with a Celery task.
 * @summary Analyse Print Job
 */
export const printJobsAnalysePrintJob = (
  printJob: PrintJob,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<AnalysePrintJobResponse>(
    {
      url: `/api/v1/print-jobs/analyse`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printJob,
    },
    options,
  );
};
/**
 * Return caxton heatmap for print job if it exists.
If not or if rerun is requested, start a task to create it and return a task id.
 * @summary Create Caxton Heatmap
 */
export const printJobsCreateCaxtonHeatmap = (
  printJob: PrintJob,
  params?: PrintJobsCreateCaxtonHeatmapParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<CaxtonHeatmapResponse>(
    {
      url: `/api/v1/print-jobs/caxton-heatmap`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printJob,
      params,
    },
    options,
  );
};
/**
 * Update a print_job.
 * @summary Update Item
 */
export const printJobsUpdateItem = (
  id: number,
  printJobUpdate: PrintJobUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob>(
    {
      url: `/api/v1/print-jobs/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: printJobUpdate,
    },
    options,
  );
};
/**
 * Get print_job by ID.
 * @summary Read Print Job
 */
export const printJobsReadPrintJob = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob>(
    { url: `/api/v1/print-jobs/${id}`, method: "GET" },
    options,
  );
};
/**
 * Delete a print_job.
 * @summary Delete Print Job
 */
export const printJobsDeletePrintJob = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJob>(
    { url: `/api/v1/print-jobs/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * Get gcode file from print_job by ID.
 * @summary Read Print Job Gcode
 */
export const printJobsReadPrintJobGcode = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobGcode>(
    { url: `/api/v1/print-jobs/${id}/gcode`, method: "GET" },
    options,
  );
};
/**
 * Get print_job by ID.
 * @summary Read Print Job Csv
 */
export const printJobsReadPrintJobCsv = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrintJobCsv>(
    { url: `/api/v1/print-jobs/${id}/csv`, method: "GET" },
    options,
  );
};
export type PrintJobsReadPrintJobsPaginatedResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJobsPaginated>>
>;
export type PrintJobsCreatePrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJob>>
>;
export type PrintJobsReadPrintJobsResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJobs>>
>;
export type PrintJobsDeleteAllPrintJobsResult = NonNullable<
  Awaited<ReturnType<typeof printJobsDeleteAllPrintJobs>>
>;
export type PrintJobsReadPrintJobByIdResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJobById>>
>;
export type PrintJobsCountPrintJobsResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCountPrintJobs>>
>;
export type PrintJobsGetSuccessRateResult = NonNullable<
  Awaited<ReturnType<typeof printJobsGetSuccessRate>>
>;
export type PrintJobsCreatePrintJobZipResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobZip>>
>;
export type PrintJobsCreatePrintJobZipRemoteResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobZipRemote>>
>;
export type PrintJobsCreatePrintJobGcodeRemoteResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobGcodeRemote>>
>;
export type PrintJobsCreatePrintJobWithPrinterChunkedResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobWithPrinterChunked>>
>;
export type PrintJobsCreatePrintJobWithPrinterResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobWithPrinter>>
>;
export type PrintJobsCreatePrintJobWithPrinterPrintResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobWithPrinterPrint>>
>;
export type PrintJobsUploadGcodeFromFileResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUploadGcodeFromFile>>
>;
export type PrintJobsUploadGcodeFromBrowserResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUploadGcodeFromBrowser>>
>;
export type PrintJobsUploadGcodeToPrinterResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUploadGcodeToPrinter>>
>;
export type PrintJobsUploadGcodeFromUrlResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUploadGcodeFromUrl>>
>;
export type PrintJobsCreatePrintJobWithPrinterPrintFromGcodeUrlResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreatePrintJobWithPrinterPrintFromGcodeUrl>>
>;
export type PrintJobsCancelPrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCancelPrintJob>>
>;
export type PrintJobsFinishPrintJobWithPrinterResult = NonNullable<
  Awaited<ReturnType<typeof printJobsFinishPrintJobWithPrinter>>
>;
export type PrintJobsUploadFirstLayerCsvResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUploadFirstLayerCsv>>
>;
export type PrintJobsDownloadPrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsDownloadPrintJob>>
>;
export type PrintJobsAnalysePrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsAnalysePrintJob>>
>;
export type PrintJobsCreateCaxtonHeatmapResult = NonNullable<
  Awaited<ReturnType<typeof printJobsCreateCaxtonHeatmap>>
>;
export type PrintJobsUpdateItemResult = NonNullable<
  Awaited<ReturnType<typeof printJobsUpdateItem>>
>;
export type PrintJobsReadPrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJob>>
>;
export type PrintJobsDeletePrintJobResult = NonNullable<
  Awaited<ReturnType<typeof printJobsDeletePrintJob>>
>;
export type PrintJobsReadPrintJobGcodeResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJobGcode>>
>;
export type PrintJobsReadPrintJobCsvResult = NonNullable<
  Awaited<ReturnType<typeof printJobsReadPrintJobCsv>>
>;
