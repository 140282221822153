/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-28T10:16:42
 */
import type {
  AnnouncementsReadAllAnnouncementsParams,
  AnnouncementsReadCurrentAnnouncementParams,
  Notification,
  NotificationCreate,
  NotificationUpdate,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve announcements.
 * @summary Read Current Announcement
 */
export const announcementsReadCurrentAnnouncement = (
  params?: AnnouncementsReadCurrentAnnouncementParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification[]>(
    { url: `/api/v1/announcements/`, method: "GET", params },
    options,
  );
};
/**
 * Create a new announcement.
 * @summary Create Announcement
 */
export const announcementsCreateAnnouncement = (
  notificationCreate: NotificationCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification>(
    {
      url: `/api/v1/announcements/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: notificationCreate,
    },
    options,
  );
};
/**
 * Retrieve all announcements.
 * @summary Read All Announcements
 */
export const announcementsReadAllAnnouncements = (
  params?: AnnouncementsReadAllAnnouncementsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification[]>(
    { url: `/api/v1/announcements/all`, method: "GET", params },
    options,
  );
};
/**
 * Update an announcement.
 * @summary Update Announcement
 */
export const announcementsUpdateAnnouncement = (
  id: number,
  notificationUpdate: NotificationUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification>(
    {
      url: `/api/v1/announcements/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: notificationUpdate,
    },
    options,
  );
};
/**
 * Delete an announcement.
 * @summary Delete Announcement
 */
export const announcementsDeleteAnnouncement = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification>(
    { url: `/api/v1/announcements/${id}`, method: "DELETE" },
    options,
  );
};
export type AnnouncementsReadCurrentAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof announcementsReadCurrentAnnouncement>>
>;
export type AnnouncementsCreateAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof announcementsCreateAnnouncement>>
>;
export type AnnouncementsReadAllAnnouncementsResult = NonNullable<
  Awaited<ReturnType<typeof announcementsReadAllAnnouncements>>
>;
export type AnnouncementsUpdateAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof announcementsUpdateAnnouncement>>
>;
export type AnnouncementsDeleteAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof announcementsDeleteAnnouncement>>
>;
